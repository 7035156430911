<template>
  <div class="home">
    <HeaderPage name="Changer son mot de passe" :noButton="true" />
    <div class="card-custom">
      <form @submit.prevent="checkForm" class="pb-3">
        <div class="form-floating mb-3">
          <input
            id="oldPassword"
            v-model="form.oldPassword"
            placeholder="Mot de passe"
            class="form-control"
            type="password"
            name="oldPassword"
            required
            minlength="6"
          />
          <label for="oldPassword">Ancien mot de passe</label>
        </div>
        <div class="form-floating mb-3">
          <input
            id="newPassword"
            v-model="form.newPassword"
            placeholder="Mot de passe"
            class="form-control"
            type="password"
            name="newPassword"
            required
            minlength="6"
          />
          <label for="newPassword">Nouveau mot de passe</label>
        </div>
        <div class="form-floating mb-3">
          <input
            id="newPasswordConfirm"
            v-model="form.newPasswordConfirm"
            placeholder="Mot de passe"
            class="form-control"
            type="password"
            name="newPasswordConfirm"
            required
            minlength="6"
          />
          <label for="newPasswordConfirm">
            Nouveau mot de passe (confirmation)
          </label>
        </div>
        <div
          v-if="
            form.newPassword &&
            form.newPasswordConfirm &&
            form.newPassword !== form.newPasswordConfirm
          "
          class="alert alert-danger"
          role="alert"
        >
          Le mot de passe et sa confirmation ne sont pas les mêmes
        </div>
        <button
          type="submit"
          class="btn btn-primary"
          :disabled="
            form.oldPassword && form.newPassword && form.newPasswordConfirm
              ? false
              : true
          "
        >
          Valider
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import { useStore } from "@/store";

import HeaderPage from "../components/HeaderPage";

export default {
  name: "ChangePassword",
  components: { HeaderPage },
  setup() {
    const store = useStore();

    const form = reactive({
      oldPassword: null,
      newPassword: null,
      newPasswordConfirm: null,
    });

    function checkForm() {
      store.dispatch("changePassword", form);
    }

    return {
      form,
      checkForm,
    };
  },
};
</script>
