<template>
  <div
    class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
  >
    <h1 class="h2" v-if="!data.isList && !data.id && !noButton">
      Création d'un{{ isFeminin ? "e" : "" }} {{ data.name }}
    </h1>
    <h1 class="h2" v-if="!data.isList && data.id && !noButton">
      Modification d'un{{ isFeminin ? "e" : "" }} {{ data.name }}
    </h1>
    <h1 class="h2" v-if="data.isList && !noButton">
      Gestion des {{ data.name }}s
    </h1>
    <h1 class="h2" v-if="noButton">{{ data.name }}</h1>
    <router-link
      v-if="!data.isList && !noButton"
      :to="`/${data.name}s`"
      class="btn btn-primary"
    >
      Listes des {{ data.name }}s
    </router-link>
    <router-link
      v-if="data.isList && !noButton"
      :to="`/${data.name}s/new`"
      class="btn btn-primary"
    >
      Ajouter un{{ isFeminin ? "e" : "" }} {{ data.name }}
    </router-link>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "HeaderPage",
  props: {
    name: String,
    isList: Boolean,
    noButton: Boolean,
    id: Number,
    isFeminin: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const data = computed({ get: () => props });
    return {
      data,
    };
  },
};
</script>
