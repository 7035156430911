import axios from "axios";
import router from "../router";

// initial state
const state = () => ({
  artistList: [],
  artistForm: {
    name: null,
    description: null,
    country: null,
    city: null,
    style: null,
    mail: null,
    number: null,
    facebook: null,
    instagram: null,
    deezer: null,
    spotify: null,
    soundcloud: null,
    bandcamp: null,
    youtube: null,
    website: null,
  },
  imageToUpload: null,
});

// getters
const getters = {
  getAllArtist: (state) => {
    return state.artistList;
  },
  getFormArtist: (state) => {
    return state.artistForm;
  },
};

// actions
const actions = {
  async getAllArtist({ commit }) {
    try {
      let data = await axios.get(
        this.getters.urlApi + "artist",
        this.getters.headerApi
      );
      commit("setArtist", data.data.data);
    } catch (error) {
      console.log(error);
      console.log(error.response.data.error);
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Error get all artist",
        status: "danger",
      });
    }
  },
  async getArtistDetail({ commit }, id) {
    try {
      let data = await axios.get(
        this.getters.urlApi + "artist/" + id,
        this.getters.headerApi
      );
      commit("setArtistDetail", data.data.data);
    } catch (error) {
      console.log(error);
      console.log(error.response.data.error);
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Error detail artist",
        status: "danger",
      });
    }
  },
  async addArtist({ state, dispatch, commit }) {
    try {
      state.loading = true;
      let header = this.getters.headerApi;
      let body = state.artistForm;
      if (state.imageToUpload) {
        header = this.getters.headerApiMultiPart;
        body = new FormData();
        for (var key in state.artistForm) {
          state.artistForm[key] !== null &&
            body.append(key, state.artistForm[key]);
        }
        body.append("image", state.imageToUpload);
      }
      await axios.post(this.getters.urlApi + "artist", body, header);
      state.loading = false;
      commit("addInfoUi", {
        msg: "Artiste créé avec succès",
        status: "success",
      });
      commit("resetArtistForm");
      dispatch("getAllArtist");
    } catch (error) {
      state.loading = false;
      console.log(error);
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Erreur d'ajout",
        status: "danger",
      });
    }
  },
  async editArtist({ state, dispatch, commit }, artistId) {
    try {
      state.loading = true;
      let header = this.getters.headerApi;
      let body = state.artistForm;
      if (state.imageToUpload) {
        console.log("editArtist whit image to upload");
        header = this.getters.headerApiMultiPart;
        body = new FormData();
        for (var key in state.artistForm) {
          state.artistForm[key] !== null &&
            body.append(key, state.artistForm[key]);
        }
        body.append("image", state.imageToUpload);
      }
      console.log("send", body);
      await axios.put(this.getters.urlApi + "artist/" + artistId, body, header);
      state.loading = false;
      commit("addInfoUi", {
        msg: "Artiste modifié avec succès",
        status: "success",
      });
      commit("resetArtistForm");
      dispatch("getAllArtist");
      router.push("/artistes");
    } catch (error) {
      state.loading = false;
      console.log(error);
      console.log(error.response.data.error);
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Erreur d'édition",
        status: "danger",
      });
    }
  },
  async deleteArtist({ state, dispatch, commit }, artistId) {
    try {
      state.loading = true;
      await axios.delete(
        this.getters.urlApi + "artist/" + artistId,
        this.getters.headerApi
      );
      state.loading = false;
      dispatch("getAllArtist");
      commit("addInfoUi", {
        msg: "Artiste supprimé avec succès",
        status: "success",
      });
      // router.push("/artistes");
    } catch (error) {
      state.loading = false;
      console.log(error);
      console.log(error.response.data.error);
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Erreur de suppression",
        status: "danger",
      });
    }
  },
};

// mutations
const mutations = {
  setArtist(state, data) {
    state.artistList = data;
  },
  setArtistDetail(state, data) {
    state.artistForm = data[0];
  },
  setImageArtist(state, value) {
    state.imageToUpload = value;
  },
  resetArtistForm(state) {
    state.imageToUpload = null;
    state.artistForm = {
      name: null,
      description: null,
      city: null,
      style: null,
      mail: null,
      number: null,
      facebook: null,
      instagram: null,
      deezer: null,
      soundcloud: null,
      bandcamp: null,
      youtube: null,
      website: null,
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
