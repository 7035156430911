import axios from "axios";
import router from "../router";

// initial state
const state = () => ({
  articleList: [],
  articleForm: {
    title: null,
    path: null,
    description: null,
    is_visible: true,
    is_promoted: false,
    categorie: [],
  },
  imageToUpload: null,
});

// getters
const getters = {
  getAllArticle: (state) => {
    return state.articleList;
  },
  getFormArticle: (state) => {
    return state.articleForm;
  },
  getDescription: (state) => {
    return state.articleForm.description;
  },
  // getIdCategories: (state) => {
  //   return state.articleForm.categorie;
  // },
};

// actions
const actions = {
  async getAllArticle({ commit }) {
    try {
      let data = await axios.get(
        this.getters.urlApi + "article/admin",
        this.getters.headerApi
      );
      commit("setArticles", data.data.data);
    } catch (error) {
      console.log(error);
      console.log(error.response.data.error);
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Error get all article",
        status: "danger",
      });
    }
  },
  async getArticleDetail({ commit }, id) {
    try {
      let data = await axios.get(
        this.getters.urlApi + "article/" + id,
        this.getters.headerApi
      );
      commit("setArticleDetail", data.data.data);
    } catch (error) {
      console.log(error);
      console.log(error.response.data.error);
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Error detail article",
        status: "danger",
      });
    }
  },
  async addArticle({ state, dispatch, commit }) {
    try {
      state.loading = true;
      let header = this.getters.headerApi;
      let body = state.articleForm;
      if (state.imageToUpload) {
        header = this.getters.headerApiMultiPart;
        body = new FormData();
        for (var key in state.articleForm) {
          state.articleForm[key] !== null &&
            body.append(key, state.articleForm[key]);
        }
        body.append("image", state.imageToUpload);
      }
      await axios.post(this.getters.urlApi + "article", body, header);
      state.loading = false;
      commit("addInfoUi", {
        msg: "Article crée avec succes",
        status: "success",
      });
      commit("resetEventForm");
      dispatch("getAllArticle");
    } catch (error) {
      state.loading = false;
      console.log(error);
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Error addArticle",
        status: "danger",
      });
    }
  },
  async editArticle({ state, dispatch, commit }, articleId) {
    try {
      state.loading = true;
      let header = this.getters.headerApi;
      let body = state.articleForm;
      if (state.imageToUpload) {
        console.log("editArticle whit image to upload");
        header = this.getters.headerApiMultiPart;
        body = new FormData();
        for (var key in state.articleForm) {
          state.articleForm[key] !== null &&
            body.append(key, state.articleForm[key]);
        }
        body.append("image", state.imageToUpload);
      }
      console.log("send", body);
      await axios.put(
        this.getters.urlApi + "article/" + articleId,
        body,
        header
      );
      state.loading = false;
      commit("addInfoUi", {
        msg: "Article modifiée avec succes",
        status: "success",
      });
      commit("resetEventForm");
      dispatch("getAllArticle");
      router.push("/articles");
    } catch (error) {
      state.loading = false;
      console.log(error);
      console.log(error.response.data.error);
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Error edit Article",
        status: "danger",
      });
    }
  },
  async deleteArticle({ state, dispatch, commit }, articleId) {
    try {
      state.loading = true;
      await axios.delete(
        this.getters.urlApi + "article/" + articleId,
        this.getters.headerApi
      );
      state.loading = false;
      dispatch("getAllArticle");
      commit("addInfoUi", {
        msg: "Article supprimée avec succes",
        status: "success",
      });
    } catch (error) {
      state.loading = false;
      console.log(error);
      console.log(error.response.data.error);
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Error delete Article",
        status: "danger",
      });
    }
  },
};

// mutations
const mutations = {
  setArticles(state, data) {
    state.articleList = data;
  },
  setArticleDetail(state, data) {
    state.articleForm = data[0];
  },
  setPath(state, value) {
    if (!state.articleForm.path) {
      state.articleForm.path = value.toLowerCase().replace(/\s/g, "-");
    }
  },
  setDescription(state, value) {
    state.articleForm.description = value;
  },
  setImage(state, value) {
    state.imageToUpload = value;
  },
  resetEventForm(state) {
    state.imageToUpload = null;
    state.articleForm = {
      title: null,
      path: null,
      description: null,
      is_visible: true,
      is_promoted: false,
      id_category: null,
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
