<template>
  <div>Deconnexion en cours ...</div>
</template>

<script>
import { onMounted } from "vue";
import { useStore } from "@/store";

export default {
  name: "Logout",
  setup() {
    const store = useStore();
    onMounted(() => {
      store.dispatch("logout");
    });

    return {};
  },
};
</script>
