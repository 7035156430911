import axios from "axios";
import jwt_decode from "jwt-decode";
import router from "../router";

// initial state
const state = () => ({
  url: process.env.VUE_APP_HTTTP,
  token: window.localStorage.getItem("jwt")
    ? window.localStorage.getItem("jwt")
    : null,
  loginForm: {
    email: null,
    password: null,
  },
  loaderApp: false,
  rememberMe: true,
  ui: [],
  sidebarOpen: false,
});

// getters
const getters = {
  getFormLogin: (state) => {
    return state.loginForm;
  },
  getCookie: () => (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2)
      return parts
        .pop()
        .split(";")
        .shift();
  },
  getRememberMe: (state) => {
    return state.rememberMe;
  },
  isConnected: (state) => {
    if (
      state.token &&
      jwt_decode(state.token) &&
      jwt_decode(state.token).exp > Date.now() / 1000
    ) {
      return true;
    }
    return false;
  },
  decodedToken: (state) => {
    if (state.token) {
      return jwt_decode(state.token);
    }
    return null;
  },
  urlApi: (state) => {
    return state.url;
  },
  ui: (state) => {
    return state.ui;
  },
  headerApi: (state) => {
    return {
      headers: {
        token: state.token,
        "Content-Type": "application/json",
      },
    };
  },
  headerApiMultiPart: (state) => {
    return {
      headers: {
        token: state.token,
        "Content-Type": "multipart/form-data",
      },
    };
  },
  sidebarStatus: (state) => {
    return state.sidebarOpen;
  },
  loaderApp: (state) => {
    return state.loaderApp;
  },
};

// actions
const actions = {
  async register({ state, commit, getters }, { form }) {
    try {
      await axios.post(state.url + "auth/signup", form, getters.headerApi);
      commit("addInfoUi", { msg: "Register success", status: "success" });
      router.push("/");
    } catch (error) {
      commit("addInfoUi", { msg: error.response.data.error, status: "danger" });
    }
  },
  async logout({ commit }) {
    commit("removeToken");
    window.localStorage.removeItem("jwt");
    document.cookie = "jwt=; -1; path=/";
    router.push("/login");
  },
  async login({ commit, state }) {
    commit("setLoader", true);
    try {
      let data = await axios.post(state.url + "auth/signin", state.loginForm);
      if (state.rememberMe) {
        commit("setToken", data.data.token);
      } else {
        commit("setCookie", data.data.token);
      }
      commit("addInfoUi", { msg: "Login success", status: "success" });
      commit("setLoader", false);
      router.push("/");
    } catch (error) {
      commit("setLoader", false);
      commit("addInfoUi", { msg: error.response.data.error, status: "danger" });
    }
  },
  async changePassword(
    { state, commit, getters },
    { oldPassword, newPassword }
  ) {
    try {
      commit("setLoader", true);
      await axios.post(
        state.url + "auth/change_password",
        { old_password: oldPassword, new_password: newPassword },
        getters.headerApi
      );
      commit("addInfoUi", {
        msg: "Changement de mot de passe validé",
        status: "success",
      });
      commit("setLoader", false);
      router.push("/logout");
    } catch (error) {
      commit("setLoader", false);
      commit("addInfoUi", { msg: error.response.data.error, status: "danger" });
    }
  },
};

// mutations
const mutations = {
  checkToken() {
    if (this.getters.decodedToken.exp < Date.now() / 1000) {
      return true;
    }
    return false;
  },
  setLoader(state, value) {
    state.loaderApp = value;
  },
  setToken(state, token) {
    window.localStorage.setItem("jwt", token);
    state.token = token;
  },
  setRememberMe(state, value) {
    state.rememberMe = value;
  },
  setCookie(state, token) {
    document.cookie = "jwt=" + token + "; path=/";
    state.token = token;
  },
  addInfoUi(state, { msg, status }) {
    const id = +new Date();
    state.ui.push({ id, msg, status });
    setTimeout(function() {
      state.ui = [];
    }, 10000);
  },
  removeToken(state) {
    state.token = null;
  },
  toggleSidebar(state, value) {
    if (value === true || value === false) {
      state.sidebarOpen = value;
    } else {
      state.sidebarOpen = !state.sidebarOpen;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
