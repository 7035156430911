<template>
  <div class="home">
    <HeaderPage name="show" :isList="false" :id="concertId" />
    <div class="card-custom">
      <form @submit.prevent="sendForm" class="pb-3">
        <div class="mb-3">
          <label for="category" class="form-label">Artiste *</label>
          <select class="form-select" v-model="form.artist">
            <option
              v-for="artist in allArtists"
              :key="artist.id"
              :value="artist.id"
              :aria-required="artist.id"
            >
              {{ artist.name }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label for="category" class="form-label">Événement *</label>
          <select class="form-select" v-model="form.event">
            <option
              v-for="event in allEvents"
              :key="event.id"
              :value="event.id"
              :aria-required="event.id"
            >
              {{ event.title }}
            </option>
          </select>
        </div>
        <div class="form-floating mb-3">
          <input
            id="started_on"
            v-model="form.started_on"
            type="datetime-local"
            placeholder="Horaire de début"
            class="form-control"
          />
          <label for="started_on">Horaire de début</label>
        </div>
        <div class="form-floating mb-3">
          <input
            id="finish_on"
            v-model="form.finish_on"
            type="datetime-local"
            placeholder="Horaire de fin"
            class="form-control"
          />
          <label for="finish_on">Horaire de fin</label>
        </div>
        <div class="form-floating mb-3">
          <input
            id="stage"
            v-model="form.stage"
            type="text"
            placeholder="Scène"
            class="form-control"
          />
          <label for="stage">Scène</label>
        </div>
        <button type="submit" class="btn btn-primary">Valider</button>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, computed } from "vue";
import { useStore } from "@/store";
import { useRoute } from "vue-router";

import HeaderPage from "../components/HeaderPage";

export default {
  name: "Concerts",
  components: { HeaderPage },
  setup() {
    const route = useRoute();
    const store = useStore();
    const allEvents = computed(() => store.getters.getAllEvent);
    const allArtists = computed(() => store.getters.getAllArtist);
    const form = computed({
      get: () => store.getters.getFormConcert,
    });
    const concertId = route.params.id || null;

    onMounted(() => {
      store.dispatch("getAllEvent");
      store.dispatch("getAllArtist");
      if (route.params.id) {
        store.dispatch("getConcertDetail", route.params.id);
      }
    });

    const sendForm = () => {
      if (route.params.id) {
        store.dispatch("editConcert", route.params.id);
      } else {
        store.dispatch("addConcert");
      }
    };

    return {
      form,
      allEvents,
      allArtists,
      sendForm,
      concertId,
    };
  },
};
</script>
