<template>
  <div class="home">
    <HeaderPage name="categorie" :isList="false" :id="categorieId" :isFeminin="true" />

    <div class="card-custom">
      <form @submit.prevent="sendForm" class="pb-3">
        <div class="form-floating mb-3">
          <input
            id="label"
            v-model="form.label"
            type="text"
            placeholder="Label"
            class="form-control"
            required
          />
          <label for="label">Label</label>
        </div>
        <button type="submit" class="btn btn-primary">Valider</button>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, watch } from "vue";
import { useStore } from "@/store";
import { useRoute } from "vue-router";

import HeaderPage from "../components/HeaderPage";

export default {
  name: "CategorieForm",
  components: { HeaderPage },
  setup() {
    const route = useRoute();
    const store = useStore();
    const form = computed({
      get: () => store.getters.getFormCategorie,
    });
    const categorieId = route.params.id || null;

    onMounted(() => {
      if (route.params.id) {
        store.dispatch("getCategorieDetail", route.params.id);
      }
    });

    const sendForm = () => {
      if (route.params.id) {
        store.dispatch("editCategorie", route.params.id);
      } else {
        store.dispatch("addCategorie");
      }
    };

    watch(
      () => route.params,
      async (newParams) => {
        if (newParams.id) {
          store.dispatch("getCategorieDetail", newParams.id);
        } else {
          store.commit("resetCategorieForm");
        }
      }
    );

    return {
      form,
      sendForm,
      categorieId,
    };
  },
};
</script>
