<template>
  <div class="form-signin">
    <form @submit.prevent="checkForm">
      <!-- <img
        class="mb-4"
        src="/docs/5.0/assets/brand/bootstrap-logo.svg"
        alt=""
        width="72"
        height="57"
      /> -->
      <h1 class="h3 mb-3 fw-normal">Connexion</h1>
      <label for="inputEmail" class="visually-hidden">Email address</label>
      <input
        type="email"
        id="inputEmail"
        class="form-control"
        placeholder="Email"
        v-model="form.email"
        required=""
        autofocus=""
      />
      <label for="inputPassword" class="visually-hidden">Password</label>
      <input
        type="password"
        id="inputPassword"
        class="form-control"
        placeholder="Password"
        v-model="form.password"
        required=""
      />
      <div class="checkbox mb-3">
        <label>
          <input type="checkbox" v-model="rememberMe" /> Remember me
        </label>
      </div>
      <button class="btn btn-lg btn-dark btn-block" type="submit">
        Connexion
      </button>
      <p class="mt-5 mb-3 text-muted">© 2020</p>
    </form>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "@/store";

export default {
  name: "Login",
  setup() {
    const store = useStore();

    const form = computed({
      get: () => store.getters.getFormLogin,
    });
    const rememberMe = computed({
      get: () => store.getters.getRememberMe,
      set: (value) => store.commit("setRememberMe", value),
    });

    function checkForm() {
      store.dispatch("login");
    }

    return {
      form,
      checkForm,
      rememberMe,
    };
  },
};
</script>

<style scoped lang="scss">
body {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .checkbox {
    font-weight: 400;
  }
  .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
    &:focus {
      z-index: 2;
    }
  }
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
