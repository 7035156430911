import axios from "axios";
import router from "../router";
import { format } from "date-fns";

// initial state
const state = () => ({
  eventList: [],
  eventForm: {
    title: null,
    description: null,
    started_on: null,
    finish_on: null,
    image: null,
    place: null,
    website: null,
    facebook: null,
    ticket: null,
    is_published: null,
    tag: null,
    ticket_widget: null
  },
  imageToUpload: null,
});

// getters
const getters = {
  getAllEvent: (state) => {
    return state.eventList;
  },
  getFormEvent: (state) => {
    return state.eventForm;
  },
};

// actions
const actions = {
  async getAllEvent({ commit }) {
    try {
      let data = await axios.get(
        this.getters.urlApi + "event",
        this.getters.headerApi
      );
      commit("setEvent", data.data.data);
    } catch (error) {
      console.log(error);
      console.log(error.response.data.error);
    }
  },
  async getEventDetail({ commit }, id) {
    try {
      let data = await axios.get(
        this.getters.urlApi + "event/admin/" + id,
        this.getters.headerApi
      );
      commit("setEventDetail", data.data.data);
    } catch (error) {
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Error detail event",
        status: "danger",
      });
    }
  },
  async addEvent({ state, dispatch, commit }) {
    try {
      let header = this.getters.headerApi;
      let body = state.eventForm;
      if (state.imageToUpload) {
        header = this.getters.headerApiMultiPart;
        body = new FormData();
        for (var key in state.eventForm) {
          state.eventForm[key] !== null &&
            body.append(key, state.eventForm[key]);
        }
        body.append("image", state.imageToUpload);
      }
      await axios.post(this.getters.urlApi + "event", body, header);
      dispatch("getAllEvent");
      commit("addInfoUi", {
        msg: "Evénement créé avec succes",
        status: "success",
      });
      commit("resetEventForm");
      router.push("/evenements");
    } catch (error) {
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Erreur d'ajout",
        status: "danger",
      });
    }
  },
  async editEvent({ state, dispatch, commit }, id) {
    try {
      let header = this.getters.headerApi;
      let body = state.eventForm;
      if (state.imageToUpload) {
        console.log("editEvent whit image to upload");
        header = this.getters.headerApiMultiPart;
        body = new FormData();
        for (var key in state.eventForm) {
          state.eventForm[key] !== null &&
            body.append(key, state.eventForm[key]);
        }
        body.append("image", state.imageToUpload);
      }
      await axios.put(this.getters.urlApi + "event/" + id, body, header);
      commit("addInfoUi", {
        msg: "Evénement modifié avec succès",
        status: "success",
      });
      commit("resetEventForm");
      dispatch("getAllEvent");
      router.push("/evenements");
    } catch (error) {
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Erreur d'édition",
        status: "danger",
      });
    }
  },
  async deleteEvent({ dispatch, commit }, id) {
    try {
      await axios.delete(
        this.getters.urlApi + "event/" + id,
        this.getters.headerApi
      );
      dispatch("getAllEvent");
      commit("addInfoUi", {
        msg: "Evénement supprimé avec succès",
        status: "success",
      });
    } catch (error) {
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Erreur de suppression",
        status: "danger",
      });
    }
  },
};

// mutations
const mutations = {
  setEvent(state, data) {
    state.eventList = data;
  },
  setImageEvent(state, value) {
    state.imageToUpload = value;
  },
  setEventDetail(state, data) {
    if (data[0].started_on) {
      data[0].started_on = format(
        new Date(data[0].started_on),
        "yyyy-MM-dd'T'HH:mm"
      );
    }
    if (data[0].finish_on) {
      data[0].finish_on = format(
        new Date(data[0].finish_on),
        "yyyy-MM-dd'T'HH:mm"
      );
    }
    state.eventForm = data[0];
  },
  resetEventForm(state) {
    state.imageToUpload = null;
    state.eventForm = {
      title: null,
      description: null,
      started_on: null,
      finish_on: null,
      image: null,
      place: null,
      event: 1,
      artist: null,
      link: null,
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
