<template>
  <div class="home">
    <HeaderPage :id="articleId" name="article" :isList="false" />
    <div class="card-custom">
      <form @submit.prevent="sendForm" class="pb-3">
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="title"
            v-model="form.title"
            placeholder="Titre"
            @change="onChangeTitle"
            required
          />
          <label for="title">Titre *</label>
        </div>
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="path"
            v-model="form.path"
            placeholder="url"
            required
          />
          <label for="path">Path *</label>
        </div>
        <div class="form-floating mb-3">
          <textarea
            class="form-control"
            :value="form.description"
            @input="updateDescription"
            placeholder="Description..."
            id="description"
            style="height: 150px"
          ></textarea>
          <label for="description">Description</label>
          <div>
            <label class="form-label" v-if="compiledMarkdown">Rendu :</label>
            <div v-html="compiledMarkdown"></div>
          </div>
        </div>
        <div class="mb-3">
          <label for="category" class="form-label">Catégorie</label>
          <select class="form-control" v-model="form.categorie" multiple>
            <option
              v-for="category in allCategories"
              :key="category.id"
              :value="category.id"
            >
              {{ category.label }}
            </option>
          </select>
        </div>
        <div class="row g-2 mb-3">
          <div class="col-md">
            <label for="is_visible">Article Visible ? *</label>
            <div class="form-check">
              <input
                id="is_visible_true"
                v-model="form.is_visible"
                class="form-check-input"
                type="radio"
                name="is_visible"
                value="true"
              />
              <label class="form-check-label" for="is_visible_true">Oui</label>
            </div>
            <div class="form-check">
              <input
                id="is_visible_false"
                v-model="form.is_visible"
                class="form-check-input"
                type="radio"
                name="is_visible"
                value="false"
              />
              <label class="form-check-label" for="is_visible_false">Non</label>
            </div>
          </div>
          <div class="col-md">
            <label for="is_promoted">Article mis en avant ? *</label>
            <div class="form-check">
              <input
                id="is_promoted_true"
                v-model="form.is_promoted"
                class="form-check-input"
                type="radio"
                name="is_promoted"
                value="true"
              />
              <label class="form-check-label" for="is_promoted_true">Oui</label>
            </div>
            <div class="form-check">
              <input
                id="is_promoted_false"
                v-model="form.is_promoted"
                class="form-check-input"
                type="radio"
                name="is_promoted"
                value="false"
              />
              <label class="form-check-label" for="is_promoted_false"
                >Non</label
              >
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label for="image">Image</label>
          <input
            id="image"
            class="form-control"
            @change="onFileChange"
            type="file"
            name="image"
            ref="inputFile"
          />
          <div v-if="form.image">
            <img :src="VUE_APP_IMAGE_URL + form.image" />
          </div>
        </div>
        <button type="submit" class="btn btn-primary">Valider</button>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, watch } from "vue";
import { useStore } from "@/store";
import { useRoute } from "vue-router";
import marked from "marked";
import HeaderPage from "../components/HeaderPage";

export default {
  name: "Event",
  components: { HeaderPage },
  setup() {
    const route = useRoute();
    const store = useStore();
    const VUE_APP_IMAGE_URL = process.env.VUE_APP_IMAGE_URL;
    const allCategories = computed(() => store.getters.getCategories);
    const form = computed({
      get: () => store.getters.getFormArticle,
    });
    // const compiledMarkdown = computed(() => store.getters.getDescription);
    const compiledMarkdown = computed(
      () =>
        store.getters.getDescription &&
        marked(store.getters.getDescription, { sanitize: true })
    );
    const articleId = route.params.id || null;

    const onChangeTitle = (e) => {
      store.commit("setPath", e.target.value);
    };
    const updateDescription = (e) => {
      store.commit("setDescription", e.target.value);
    };
    const onFileChange = (e) => {
      store.commit("setImage", e.target.files[0]);
    };

    onMounted(() => {
      store.dispatch("getAllCategories");
      if (route.params.id) {
        store.dispatch("getArticleDetail", route.params.id);
      }
    });

    const sendForm = () => {
      if (route.params.id) {
        store.dispatch("editArticle", route.params.id);
      } else {
        store.dispatch("addArticle");
      }
    };

    watch(
      () => route.params,
      async (newParams) => {
        if (newParams.id) {
          store.dispatch("getEventDetail", newParams.id);
        } else {
          store.commit("resetEventForm");
        }
      }
    );

    return {
      form,
      VUE_APP_IMAGE_URL,
      allCategories,
      sendForm,
      onChangeTitle,
      onFileChange,
      articleId,
      updateDescription,
      compiledMarkdown,
    };
  },
};
</script>
<style scoped lang="scss">
img {
  max-width: 200px;
}
</style>