<template>
  <div class="home">
    <HeaderPage name="artiste" :isList="false" :id="artistId" />

    <div class="card-custom">
      <form @submit.prevent="sendForm" class="pb-3">
        <div class="form-floating mb-3">
          <input
            id="name"
            v-model="form.name"
            type="text"
            placeholder="Nom"
            class="form-control"
            required
          />
          <label for="name">Nom *</label>
        </div>
        <div class="form-floating mb-3">
          <textarea
            class="form-control"
            v-model="form.description"
            placeholder="Description..."
            id="description"
            style="height: 150px"
          ></textarea>
          <label for="description">Description</label>
        </div>
        <div class="form-floating mb-3">
          <input
            id="city"
            v-model="form.country"
            type="text"
            placeholder="Nom"
            class="form-control"
          />
          <label for="country">Pays</label>
        </div>
        <div class="form-floating mb-3">
          <input
            id="city"
            v-model="form.city"
            type="text"
            placeholder="Nom"
            class="form-control"
          />
          <label for="city">Ville</label>
        </div>
        <div class="form-floating mb-3">
          <input
            id="style"
            v-model="form.style"
            type="text"
            placeholder="Nom"
            class="form-control"
          />
          <label for="style">Genre musical</label>
        </div>
        <div class="form-floating mb-3">
          <input
            id="mail"
            v-model="form.mail"
            type="email"
            placeholder="Nom"
            class="form-control"
          />
          <label for="mail">Email</label>
        </div>
        <div class="form-floating mb-3">
          <input
            id="number"
            v-model="form.number"
            type="number"
            placeholder="Nom"
            class="form-control"
          />
          <label for="number">Nombre d'artiste(s)</label>
        </div>
        <div class="form-floating mb-3">
          <input
            id="youtube_embed"
            v-model="form.youtube_embed"
            type="youtube_embed"
            placeholder="Nom"
            class="form-control"
          />
          <label for="nyoutube_embed">Vidéo mise en avant (ID)</label>
        </div>
        <div class="mb-3">
          <label for="image">Image</label>
          <input
            id="image"
            class="form-control"
            @change="onFileChange"
            type="file"
            name="image"
            ref="inputFile"
          />
          <div v-if="form.image">
            <img :src="VUE_APP_IMAGE_URL + form.image" />
          </div>
        </div>
        <div class="mx-3">
          <strong>Réseaux sociaux</strong>
          <div class="form-floating mb-3">
            <input
              id="facebook"
              v-model="form.facebook"
              type="text"
              placeholder="Facebook"
              class="form-control"
            />
            <label for="facebook">Facebook</label>
          </div>
          <div class="form-floating mb-3">
            <input
              id="instagram"
              v-model="form.instagram"
              type="text"
              placeholder="Instagram"
              class="form-control"
            />
            <label for="instagram">Instagram</label>
          </div>
          <div class="form-floating mb-3">
            <input
              id="deezer"
              v-model="form.deezer"
              type="text"
              placeholder="Deezer"
              class="form-control"
            />
            <label for="deezer">Deezer</label>
          </div>
          <div class="form-floating mb-3">
            <input
              id="spotify"
              v-model="form.spotify"
              type="text"
              placeholder="Spotify"
              class="form-control"
            />
            <label for="spotify">Spotify</label>
          </div>
          <div class="form-floating mb-3">
            <input
              id="soundcloud"
              v-model="form.soundcloud"
              type="text"
              placeholder="Soundcloud"
              class="form-control"
            />
            <label for="soundcloud">Soundcloud</label>
          </div>
          <div class="form-floating mb-3">
            <input
              id="bandcamp"
              v-model="form.bandcamp"
              type="text"
              placeholder="Bandcamp"
              class="form-control"
            />
            <label for="bandcamp">Bandcamp</label>
          </div>
          <div class="form-floating mb-3">
            <input
              id="youtube"
              v-model="form.youtube"
              type="text"
              placeholder="Youtube"
              class="form-control"
            />
            <label for="youtube">Youtube</label>
          </div>
          <div class="form-floating mb-3">
            <input
              id="website"
              v-model="form.website"
              type="text"
              placeholder="Site Web"
              class="form-control"
            />
            <label for="website">Site Web</label>
          </div>
        </div>
        <button type="submit" class="btn btn-primary">Valider</button>
      </form>
    </div>
  </div>
</template>

<script>
  import { onMounted, computed, watch } from "vue";
  import { useStore } from "@/store";
  import { useRoute } from "vue-router";

  import HeaderPage from "../components/HeaderPage";

  export default {
    name: "ArtistForm",
    components: { HeaderPage },
    setup() {
      const route = useRoute();
      const store = useStore();
      const form = computed({
        get: () => store.getters.getFormArtist,
      });
      const artistId = route.params.id || null;

      onMounted(() => {
        if (route.params.id) {
          store.dispatch("getArtistDetail", route.params.id);
        }
      });

      const onFileChange = (e) => {
        store.commit("setImageArtist", e.target.files[0]);
      };

      const sendForm = () => {
        if (route.params.id) {
          store.dispatch("editArtist", route.params.id);
        } else {
          store.dispatch("addArtist");
        }
      };

      watch(
        () => route.params,
        async (newParams) => {
          if (newParams.id) {
            store.dispatch("getArtistDetail", newParams.id);
          } else {
            store.commit("resetArtistForm");
          }
        }
      );

      return {
        form,
        onFileChange,
        sendForm,
        artistId,
      };
    },
  };
</script>
