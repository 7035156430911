<template>
  <div class="home">
    <HeaderPage
      name="Plateforme d'administration d'Amarres Production"
      :noButton="true"
    />

    <div id="dashboard">
      <p>En cours de développement !</p>
    </div>
  </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage";

export default {
  name: "Home",
  components: { HeaderPage }
};
</script>

<style lang="scss">
// #dashboard {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 0 50px;
//   margin-top: 20px;
// }
// .row {
//   display: flex;
//   width: 100%;
//   .card:first-child {
//     margin-right: 20px;
//   }
//   .card:last-child {
//     margin-left: 20px;
//   }
// }
</style>
