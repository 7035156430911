import axios from "axios";
import router from "../router";
import { format } from "date-fns";

// initial state
const state = () => ({
  concertList: [],
  concertForm: {
    title: null,
    description: null,
    started_on: null,
    finish_on: null,
    image: null,
    place: null,
    event: 1,
    artist: null,
    link: null,
  },
  imageToUpload: null,
});

// getters
const getters = {
  getAllConcert: (state) => {
    return state.concertList;
  },
  getFormConcert: (state) => {
    return state.concertForm;
  },
};

// actions
const actions = {
  async getAllConcert({ commit }) {
    try {
      let data = await axios.get(
        this.getters.urlApi + "concert",
        this.getters.headerApi
      );
      commit("setConcert", data.data.data);
    } catch (error) {
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Error chargement concert",
        status: "danger",
      });
    }
  },
  // async getConcertArtistName({ commit }, artistId) {
  //   try {
  //     let data = await axios.get(
  //       this.getters.urlApi + "artist/" + artistId,
  //       this.getters.headerApi
  //     );
  //     const artistName = data.data.data[0].name
  //     return artistName;
  //   } catch (error) {
  //     commit("addInfoUi", {
  //       msg: error.response.data.error
  //         ? error.response.data.error
  //         : "Error chargement des noms d'artistes",
  //       status: "danger",
  //     });
  //   }
  // },
  async addConcert({ state, dispatch, commit }) {
    try {
      let header = this.getters.headerApi;
      let body = state.concertForm;
      if (state.imageToUpload) {
        header = this.getters.headerApiMultiPart;
        body = new FormData();
        for (var key in state.concertForm) {
          state.concertForm[key] !== null &&
            body.append(key, state.concertForm[key]);
        }
        body.append("image", state.imageToUpload);
      }
      await axios.post(this.getters.urlApi + "concert", body, header);
      dispatch("getAllConcert");
      commit("addInfoUi", {
        msg: "Concert crée avec succes",
        status: "success",
      });
      commit("resetConcertForm");
      router.push("/shows");
    } catch (error) {
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Error ajout concert",
        status: "danger",
      });
    }
  },
  async getConcertDetail({ commit }, id) {
    try {
      let data = await axios.get(
        this.getters.urlApi + "concert/admin/" + id,
        this.getters.headerApi
      );
      commit("setConcertDetail", data.data.data);
    } catch (error) {
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Error detail concert",
        status: "danger",
      });
    }
  },
  async editConcert({ state, dispatch, commit }, id) {
    try {
      let header = this.getters.headerApi;
      let body = state.concertForm;
      if (state.imageToUpload) {
        console.log("editConcert whit image to upload");
        header = this.getters.headerApiMultiPart;
        body = new FormData();
        for (var key in state.concertForm) {
          state.concertForm[key] !== null &&
            body.append(key, state.concertForm[key]);
        }
        body.append("image", state.imageToUpload);
      }
      await axios.put(this.getters.urlApi + "concert/" + id, body, header);
      commit("addInfoUi", {
        msg: "Concert modifié avec succes",
        status: "success",
      });
      commit("resetConcertForm");
      dispatch("getAllConcert");
      router.push("/shows");
    } catch (error) {
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Error edit Concert",
        status: "danger",
      });
    }
  },
  async deleteConcert({ dispatch, commit }, id) {
    try {
      await axios.delete(
        this.getters.urlApi + "concert/" + id,
        this.getters.headerApi
      );
      dispatch("getAllConcert");
      commit("addInfoUi", {
        msg: "Concert supprimé avec succes",
        status: "success",
      });
    } catch (error) {
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Error delete Concert",
        status: "danger",
      });
    }
  },
};

// mutations
const mutations = {
  setConcert(state, data) {
    state.concertList = data;
  },
  setImageConcert(state, value) {
    state.imageToUpload = value;
  },
  setConcertDetail(state, data) {
    if (data[0].started_on) {
      data[0].started_on = format(
        new Date(data[0].started_on),
        "yyyy-MM-dd'T'HH:mm"
      );
    }
    if (data[0].finish_on) {
      data[0].finish_on = format(
        new Date(data[0].finish_on),
        "yyyy-MM-dd'T'HH:mm"
      );
    }
    state.concertForm = data[0];
  },
  resetConcertForm(state) {
    state.imageToUpload = null;
    state.concertForm = {
      title: null,
      description: null,
      started_on: null,
      finish_on: null,
      image: null,
      place: null,
      event: 1,
      artist: null,
      link: null,
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
