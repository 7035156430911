<template>
  <div style="position: relative">
    <Loader v-if="loader" />
    <div class="container-fluid">
      <div class="row">
        <Sidebar />
        <main class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
          <button
            class="navbar-toggler position-absolute d-md-none collapsed"
            type="button"
            v-on:click.stop="toggleSidebar"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
            Menu
          </button>
          <router-view />
        </main>
      </div>
    </div>
    <div
      class="toast-container"
      v-if="ui"
      style="position: fixed; top: 0; right: 0"
    >
      <div
        v-for="item in ui"
        :key="item.id"
        :class="
          'toast d-flex align-items-center text-white border-0 fade show bg-' +
          item.status
        "
        style="z-index: 9999; position: relative"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div class="toast-body">{{ item.msg }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import Sidebar from "@/components/Sidebar.vue";

import { computed } from "vue";
import { useStore } from "@/store";

export default {
  components: {
    Loader,
    Sidebar,
  },
  setup() {
    const store = useStore();
    const ui = computed({ get: () => store.getters.ui });
    const loader = computed({ get: () => store.getters.loaderApp });
    const toggleSidebar = () => {
      store.commit("toggleSidebar");
    };

    return {
      toggleSidebar,
      ui,
      loader,
    };
  },
};
</script>

<style lang="scss">
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
  :root {
    --bs-primary: #000;
  }
  * {
    box-sizing: border-box;
  }
  body {
    font-family: "Montserrat", Helvetica, sans-serif;
    font-weight: 400;
    color: #31344b;
    background-color: #f4f4f4;
    min-height: 100vh;
    margin: 0;
  }
  #app {
    min-height: 100vh;
    height: 100%;
  }
  .home {
    padding-bottom: 16px;
    & > div {
      padding-left: 14px;
      padding-right: 14px;
      margin-top: 20px;
    }
  }
  .card-custom {
    background-color: #fff;
    border-radius: 10px;
    padding: 1rem;
    border: 1px solid #dadce0;
  }
</style>
