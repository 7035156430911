<template>
  <div class="home">
    <HeaderPage name="evenement" :isList="true" />
    <div class="card-custom">
      <table class="table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Title</th>
            <th>Date</th>
            <th>Lieu</th>
            <th>Publié</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="event in eventList" :key="event.id">
            <td>{{ event.id }}</td>
            <td>{{ event.title }}</td>
            <td>
              {{
                event.started_on
                  ? format(new Date(event.started_on), "dd/MM/yyyy")
                  : "-"
              }}
            </td>
            <td>{{ event.place ? event.place : "-" }}</td>
            <td>{{ event.is_published ? event.is_published : "-" }}</td>
            <td class="td-actions">
              <router-link
                :to="'/evenements/' + event.id"
                class="btn btn btn-outline-warning mr-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-pen"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M13.498.795l.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"
                  />
                </svg>
                Modifier
              </router-link>
              <button
                v-on:click.stop="deleteEvent(event.id)"
                class="btn btn btn-outline-danger"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-trash"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
                Supprimer
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { onMounted, computed } from "vue";
import { useStore } from "@/store";

import { format } from "date-fns";

import HeaderPage from "../components/HeaderPage";

export default {
  name: "ConcertList",
  components: { HeaderPage },
  setup() {
    const store = useStore();
    const eventList = computed(() => store.getters.getAllEvent);
    const VUE_APP_IMAGE_URL = process.env.VUE_APP_IMAGE_URL;
    onMounted(() => {
      store.dispatch("getAllEvent");
    });

    const deleteEvent = (id) => {
      store.dispatch("deleteEvent", id);
    };

    return {
      eventList,
      deleteEvent,
      VUE_APP_IMAGE_URL,
      format,
    };
  },
};
</script>
<style scoped lang="scss">
img {
  max-width: 200px;
}
tbody {
  tr {
    height: 100px;
    td {
      vertical-align: middle;
    }
  }
}
</style>