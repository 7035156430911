import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Register from "../views/Register.vue";
import ChangePassword from "../views/ChangePassword.vue";
import Article from "../views/Article.vue";
import Children from "../views/Children.vue";
import CategorieForm from "../views/CategorieForm.vue";
import CategoriesList from "../views/CategoriesList.vue";
import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";
import ArticleList from "../views/ArticleList.vue";
import ShowForm from "../views/ShowForm.vue";
import ShowList from "../views/ShowList.vue";
import EventForm from "../views/EventForm.vue";
import EventList from "../views/EventList.vue";
import ArtistForm from "../views/ArtistForm.vue";
import ArtistList from "../views/ArtistList.vue";
import { useStore } from "@/store";
import { computed } from "vue";

// const store = useStore();
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: (to, from, next) => {
      document.title = "Administration Amarres Production";
      ifAuthenticated(to, from, next);
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    beforeEnter: (to, from, next) => {
      document.title = "Ajouter un utlisateur - Admin Amarres Production";
      ifAuthenticated(to, from, next);
    },
  },
  {
    path: "/change-password",
    name: "Change Password",
    component: ChangePassword,
    beforeEnter: (to, from, next) => {
      document.title = "Changer mon mot de passe - Admin Amarres Production";
      ifAuthenticated(to, from, next);
    },
  },
  {
    path: "/articles",
    name: "Articles",
    component: Children,
    children: [
      {
        path: "",
        name: "Articles list",
        component: ArticleList,
        beforeEnter: (to, from, next) => {
          document.title = "Listes des articles - Admin Amarres Production";
          ifAuthenticated(to, from, next);
        },
      },
      {
        path: "new",
        name: "New article",
        component: Article,
        beforeEnter: (to, from, next) => {
          document.title = "Ajouter un article - Admin Amarres Production";
          ifAuthenticated(to, from, next);
        },
      },
      {
        path: ":id",
        name: "Edit article",
        component: Article,
        beforeEnter: (to, from, next) => {
          document.title = "Modifier un article - Admin Amarres Production";
          ifAuthenticated(to, from, next);
        },
      },
    ],
  },
  {
    path: "/categories",
    name: "Tags",
    component: Children,
    children: [
      {
        path: "",
        name: "Tags list",
        component: CategoriesList,
        beforeEnter: (to, from, next) => {
          document.title = "Liste des catégories - Admin Amarres Production";
          ifAuthenticated(to, from, next);
        },
      },
      {
        path: "new",
        name: "New tag",
        component: CategorieForm,
        beforeEnter: (to, from, next) => {
          document.title = "Ajouter une catégorie - Admin Amarres Production";
          ifAuthenticated(to, from, next);
        },
      },
      {
        path: ":id",
        name: "Edit tag",
        component: CategorieForm,
        beforeEnter: (to, from, next) => {
          document.title = "Modifier une catégorie - Admin Amarres Production";
          ifAuthenticated(to, from, next);
        },
      },
    ],
  },
  {
    path: "/evenements",
    name: "Events",
    component: Children,
    children: [
      {
        path: "",
        name: "Events list",
        component: EventList,
        beforeEnter: (to, from, next) => {
          document.title = "Listes des événements - Admin Amarres Production";
          ifAuthenticated(to, from, next);
        },
      },
      {
        path: "new",
        name: "New event",
        component: EventForm,
        beforeEnter: (to, from, next) => {
          document.title = "Ajouter un événement - Admin Amarres Production";
          ifAuthenticated(to, from, next);
        },
      },
      {
        path: ":id",
        name: "Edit event",
        component: EventForm,
        beforeEnter: (to, from, next) => {
          document.title = "Modifier un événement - Admin Amarres Production";
          ifAuthenticated(to, from, next);
        },
      }
    ],
  },
  {
    path: "/shows",
    name: "Shows",
    component: Children,
    children: [
      {
        path: "",
        name: "Shows list",
        component: ShowList,
        beforeEnter: (to, from, next) => {
          document.title = "Listes des concerts - Admin Amarres Production";
          ifAuthenticated(to, from, next);
        },
      },
      {
        path: "new",
        name: "New show",
        component: ShowForm,
        beforeEnter: (to, from, next) => {
          document.title = "Ajouter un concert - Admin Amarres Production";
          ifAuthenticated(to, from, next);
        },
      },
      {
        path: ":id",
        name: "Edit show",
        component: ShowForm,
        beforeEnter: (to, from, next) => {
          document.title = "Modifier un concert - Admin Amarres Production";
          ifAuthenticated(to, from, next);
        },
      },
    ],
  },
  {
    path: "/artistes",
    name: "Artists",
    component: Children,
    children: [
      {
        path: "",
        name: "Artists list",
        component: ArtistList,
        beforeEnter: (to, from, next) => {
          document.title = "Listes des artistes - Admin Amarres Production";
          ifAuthenticated(to, from, next);
        },
      },
      {
        path: "new",
        name: "New artist",
        component: ArtistForm,
        beforeEnter: (to, from, next) => {
          document.title = "Modifier un artiste - Admin Amarres Production";
          ifAuthenticated(to, from, next);
        },
      },
      {
        path: ":id",
        name: "Edit artist",
        component: ArtistForm,
        beforeEnter: (to, from, next) => {
          document.title = "Modifier un artiste - Admin Amarres Production";
          ifAuthenticated(to, from, next);
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: () => {
      document.title = "Connexion - Admin Amarres Production";
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
];

const ifAuthenticated = async (to, from, next) => {
  const store = useStore();
  const isConnected = computed({ get: () => store.getters.isConnected });
  if (isConnected.value) {
    store.commit("toggleSidebar", false);
    next();
    return;
  }
  next("/login");
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
