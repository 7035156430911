<template>
  <div class="container-loader">
    <div class="preloader-2">
      <span class="line line-1"></span>
      <span class="line line-2"></span>
      <span class="line line-3"></span>
      <span class="line line-4"></span>
      <span class="line line-5"></span>
      <span class="line line-6"></span>
      <span class="line line-7"></span>
      <span class="line line-8"></span>
      <span class="line line-9"></span>
      <div>Chargement</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style scoped lang="scss">
.container-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background: rgba($color: #000000, $alpha: 0.5);
  margin: 0;
}
.preloader-2 {
  // margin: 120px auto 0;
  z-index: 999;
}

div {
  color: #fff;
  margin: 5px 0;
  text-transform: uppercase;
  text-align: center;
  font-family: "Arial", sans-serif;
  font-size: 10px;
  letter-spacing: 2px;
}

.preloader-2 .line {
  width: 1px;
  height: 12px;
  background: #fff;
  margin: 0 1px;
  display: inline-block;
  animation: opacity-2 1000ms infinite ease-in-out;
}

.preloader-2 .line-1 {
  animation-delay: 800ms;
}
.preloader-2 .line-2 {
  animation-delay: 600ms;
}
.preloader-2 .line-3 {
  animation-delay: 400ms;
}
.preloader-2 .line-4 {
  animation-delay: 200ms;
}
.preloader-2 .line-6 {
  animation-delay: 200ms;
}
.preloader-2 .line-7 {
  animation-delay: 400ms;
}
.preloader-2 .line-8 {
  animation-delay: 600ms;
}
.preloader-2 .line-9 {
  animation-delay: 800ms;
}

@keyframes opacity-2 {
  0% {
    opacity: 1;
    height: 15px;
  }
  50% {
    opacity: 0;
    height: 12px;
  }
  100% {
    opacity: 1;
    height: 15px;
  }
}
</style>