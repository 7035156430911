<template>
  <div class="home">
    <HeaderPage name="Ajouter un utilisateur" :noButton="true" />

    <div class="card-custom">
      <form @submit.prevent="checkForm" class="pb-3">
        <div class="row g-2 mb-1">
          <div class="col-6 form-floating mb-3">
            <input
              id="firstName"
              v-model="form.first_name"
              type="text"
              name="firstName"
              placeholder="Prénom"
              class="form-control"
              required
            />
            <label for="firstName">Prénom</label>
          </div>
          <div class="col-6 form-floating mb-3">
            <input
              id="lastName"
              v-model="form.last_name"
              placeholder="Nom"
              type="text"
              class="form-control"
              name="lastName"
              required
            />
            <label for="lastName">Nom</label>
          </div>
        </div>
        <div class="form-floating mb-3">
          <input
            id="email"
            v-model="form.email"
            placeholder="Email"
            class="form-control"
            type="email"
            name="email"
            required
          />
          <label for="email">Email</label>
        </div>
        <div class="form-floating mb-3">
          <input
            id="password"
            v-model="form.password"
            placeholder="Mot de passe"
            class="form-control"
            type="text"
            name="password"
            required
            minlength="6"
            disabled
          />
          <label for="password">Mot de passe</label>
        </div>
        <button type="submit" class="btn btn-primary">Valider</button>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import { useStore } from "@/store";

import HeaderPage from "../components/HeaderPage";

export default {
  name: "Register",
  components: { HeaderPage },
  setup() {
    const store = useStore();

    const form = reactive({
      first_name: "",
      last_name: "",
      email: "",
      password: Math.random().toString(36).slice(-10),
    });

    function checkForm() {
      console.log("form", form);
      store.dispatch("register", { form });
    }

    return {
      form,
      checkForm,
    };
  },
};
</script>
