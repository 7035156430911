<template>
  <div class="home">
    <HeaderPage name="evenement" :isList="false" :id="eventId" />
    <div class="card-custom">
      <form @submit.prevent="sendForm" class="pb-3">
        <div class="form-floating mb-3">
          <input
            id="title"
            v-model="form.title"
            type="text"
            placeholder="Titre"
            class="form-control"
            required
          />
          <label for="title">Titre *</label>
        </div>
        <div class="form-floating mb-3">
          <textarea
            class="form-control"
            v-model="form.description"
            placeholder="Description"
            id="description"
            style="height: 150px"
          ></textarea>
          <label for="description">Description</label>
        </div>
        <div class="form-floating mb-3">
          <input
            id="started_on"
            v-model="form.started_on"
            type="datetime-local"
            placeholder="Date de début"
            class="form-control"
          />
          <label for="started_on">Date de début</label>
        </div>
        <div class="form-floating mb-3">
          <input
            id="finish_on"
            v-model="form.finish_on"
            type="datetime-local"
            placeholder="Date de fin"
            class="form-control"
          />
          <label for="finish_on">Date de fin</label>
        </div>
        <div class="mb-3">
          <label for="image">Image</label>
          <input
            id="image"
            class="form-control"
            @change="onFileChange"
            type="file"
            name="image"
            ref="inputFile"
          />
          <div v-if="form.image">
            <img :src="VUE_APP_IMAGE_URL + form.image" />
          </div>
        </div>
        <div class="form-floating mb-3">
          <input
            id="place"
            v-model="form.place"
            type="text"
            placeholder="Lieu"
            class="form-control"
          />
          <label for="place">Lieu / Adresse</label>
        </div>
        <div class="form-floating mb-3">
          <input
            id="city"
            v-model="form.city"
            type="text"
            placeholder="Ville"
            class="form-control"
          />
          <label for="city">Ville</label>
        </div>
        <div class="form-floating mb-3">
          <input
            id="zipcode"
            v-model="form.zipcode"
            type="text"
            placeholder="Code postal"
            class="form-control"
          />
          <label for="zipcode">Code postal</label>
        </div>
        <div class="form-floating mb-3">
          <input
            id="website"
            v-model="form.website"
            type="text"
            placeholder="Site Web"
            class="form-control"
          />
          <label for="website">Site Web</label>
        </div>
        <div class="form-floating mb-3">
          <input
            id="facebook"
            v-model="form.facebook"
            type="text"
            placeholder="Facebook"
            class="form-control"
          />
          <label for="facebook">Facebook</label>
        </div>
        <div class="form-floating mb-3">
          <input
            id="tickets"
            v-model="form.tickets"
            type="text"
            placeholder="Billetterie"
            class="form-control"
          />
          <label for="tickets">Billetterie</label>
        </div>
        <div class="form-floating mb-3">
          <textarea
            id="ticket_widget"
            v-model="form.ticket_widget"
            placeholder="Widget billetterie"
            class="form-control"
            style="height: 150px"
          />
          <label for="ticket_widget">Widget billetterie</label>
        </div>
        <div class="form-floating mb-3">
          <input
            id="tag"
            v-model="form.tag"
            type="text"
            placeholder="Tag"
            class="form-control"
          />
          <label for="tag">Tag</label>
        </div>
        <div class="row g-2 mb-3">
          <div class="col-md">
          <label for="is_published">Publier l'événement ?</label>
          <div class="form-check">
            <input
              id="is_published_true"
              v-model="form.is_published"
              class="form-check-input"
              type="radio"
              name="is_published"
              value="true"
            />
            <label class="form-check-label" for="is_published_true">Oui</label>
          </div>
          <div class="form-check">
            <input
              id="is_published_false"
              v-model="form.is_published"
              class="form-check-input"
              type="radio"
              name="is_published"
              value="false"
              checked
            />
            <label class="form-check-label" for="is_published_false">Non</label>
          </div>
        </div>
        </div>
        <button type="submit" class="btn btn-primary">Valider</button>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, watch } from "vue";
import { useStore } from "@/store";
import { useRoute } from "vue-router";

import HeaderPage from "../components/HeaderPage";

export default {
  name: "Events",
  components: { HeaderPage },
  setup() {
    const route = useRoute();
    const store = useStore();
    const VUE_APP_IMAGE_URL = process.env.VUE_APP_IMAGE_URL;
    const form = computed({
      get: () => store.getters.getFormEvent,
    });
    const eventId = route.params.id || null;

    onMounted(() => {
      if (route.params.id) {
        store.dispatch("getEventDetail", route.params.id);
      }
    });

    const sendForm = () => {
      if (route.params.id) {
        store.dispatch("editEvent", route.params.id);
      } else {
        store.dispatch("addEvent");
      }
    };

    const onFileChange = (e) => {
      store.commit("setImageEvent", e.target.files[0]);
    };

    watch(
      () => route.params,
      async (newParams) => {
        if (newParams.id) {
          store.dispatch("getEventDetail", newParams.id);
        } else {
          store.commit("resetEventForm");
        }
      }
    );

    return {
      form,
      sendForm,
      eventId,
      onFileChange,
      VUE_APP_IMAGE_URL,
    };
  },
};
</script>
