import axios from "axios";
import router from "../router";

// initial state
const state = () => ({
  categoriesList: [],
  loading: false,
  categorieForm: {
    label: null,
    value: null,
  },
});

// getters
const getters = {
  getCategories: (state) => {
    return state.categoriesList;
  },
  getFormCategorie: (state) => {
    return state.categorieForm;
  },
};

// actions
const actions = {
  async getAllCategories({ commit }) {
    try {
      let data = await axios.get(
        this.getters.urlApi + "categorie",
        this.getters.headerApi
      );
      commit("setCatgories", data.data.data);
    } catch (error) {
      console.log(error);
      console.log(error.response.data.error);
    }
  },
  async getCategorieDetail({ commit }, id) {
    try {
      let data = await axios.get(
        this.getters.urlApi + "categorie/" + id,
        this.getters.headerApi
      );
      commit("setCategorieDetail", data.data.data);
    } catch (error) {
      console.log(error);
      console.log(error.response.data.error);
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Error detail categorie",
        status: "danger",
      });
    }
  },
  async addCategorie({ state, dispatch, commit }) {
    try {
      state.loading = true;
      await axios.post(
        this.getters.urlApi + "categorie",
        state.categorieForm,
        this.getters.headerApi
      );
      state.loading = false;
      dispatch("getAllCategories");
      commit("addInfoUi", {
        msg: "Categorie créée avec succes",
        status: "success",
      });
    } catch (error) {
      state.loading = false;
      console.log(error);
      console.log(error.response.data.error);
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Error ajout categorie",
        status: "danger",
      });
    }
  },
  async editCategorie({ state, dispatch, commit }, categorieId) {
    try {
      state.loading = true;
      await axios.put(
        this.getters.urlApi + "categorie/" + categorieId,
        state.categorieForm,
        this.getters.headerApi
      );
      state.loading = false;
      dispatch("getAllCategories");
      commit("addInfoUi", {
        msg: "Categorie modifiée avec succes",
        status: "success",
      });
      router.push("/categories");
    } catch (error) {
      state.loading = false;
      console.log(error);
      console.log(error.response.data.error);
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Error modification categorie",
        status: "danger",
      });
    }
  },
  async deleteCategorie({ state, dispatch, commit }, categorieId) {
    try {
      state.loading = true;
      await axios.delete(
        this.getters.urlApi + "categorie/" + categorieId,
        this.getters.headerApi
      );
      state.loading = false;
      dispatch("getAllCategories");
    } catch (error) {
      state.loading = false;
      console.log(error);
      console.log(error.response.data.error);
      commit("addInfoUi", {
        msg: "Categorie supprimée avec succes",
        status: "success",
      });
      commit("addInfoUi", {
        msg: error.response.data.error
          ? error.response.data.error
          : "Error suppression categorie",
        status: "danger",
      });
    }
  },
};

// mutations
const mutations = {
  setCatgories(state, data) {
    state.categoriesList = data;
  },
  setCategorieDetail(state, data) {
    state.categorieForm = data[0];
  },
  resetCategorieForm(state) {
    state.categorieForm = {
      label: null,
      value: null,
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
