import { createStore } from "vuex";
import auth from "./auth";
import article from "./article";
import categories from "./categories";
import event from "./event";
import concert from "./concert";
import artist from "./artist";

export const store = createStore({
  modules: {
    auth,
    article,
    categories,
    event,
    concert,
    artist,
  },
});

export function useStore() {
  return store;
}
